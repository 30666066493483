.sam-gnb,.sam-footer{ background-color: #404040; width: 100%; height: 50px;}
section[class^="section"] {width: 100%; position: relative; overflow-x: hidden;}
section[class^="section"] > .container{width: 100%; max-width: 1240px; padding: 0px 20px; margin: 0px auto;}
.display-pc{display: block !important;}
.display-mo{display: none !important;}
a{cursor: pointer;}
a.btn{display: flex; align-items: center; justify-content: center;}
a.btn.btn-r{background-color: #E45457; color: #fff; border:2px solid #E45457;}
a.btn.btn-w{background-color: #fff; color: #E45457; border:2px solid #E45457;}
.img-wrap{line-height: 0;}
img{max-width: 100%; height: auto; object-fit: contain;}
/* .swiper {height: 282px !important;} */
.swiper {height: auto !important;}
.swiper .slider_item .img-wrap {padding: 10px;}

.container h1{color: #000; font-family: 'Pretendard'; font-size: 52px; font-style: normal; font-weight: 700; line-height: 70px;}
.container h2{color: #000; font-family: 'Pretendard'; font-size: 44px; font-style: normal; font-weight: 700; line-height: 64px;}
.container h3{color: #1E1E1E; text-align: center; font-family: 'Pretendard'; font-size: 40px; font-style: normal; font-weight: 700; line-height: 180%;}
.container p{color: #717171; font-family: 'Pretendard'; font-size: 24px; font-style: normal; font-weight: 500; line-height: 180%;}

.section1 {position:fixed !important; z-index: 3;}
.section1.on {visibility: visible; opacity: 1;transition: 0.3s;}
.section1.off {visibility: hidden; opacity: 0;transition: 0.3s;}
.section1 .container .btn-wrap{margin: 25.5px 0 5.5px 0;display: flex; gap: 12px; justify-content: flex-end;}
.section1 .container .btn-wrap a.btn{color: #625F5F; font-family: 'Pretendard'; font-size: 16px; font-style: normal; font-weight: 600; width: 100px; height: 36px; border-radius: 8px; border: 1px solid #D2D2D2;}
.section1 .container .btn-wrap a.btn:nth-child(1){background: #DAF8F6;}
.section1 .container .btn-wrap a.btn:nth-child(2){background: #EAEAFF;}
.section1 .container .btn-wrap a.btn:nth-child(3){background: #D4F7FF;}
.section1 .container .btn img.logo{width: 24px; height: 24px; margin-left: 8px;}
.section1 .container .btn-wrap a.btn.shadow{box-shadow: 1px 2px 13px -6px rgba(41,41,41,1);-webkit-box-shadow: 1px 2px 13px -6px rgba(41,41,41,1);-moz-box-shadow: 1px 2px 13px -6px rgba(41,41,41,1);}

/* .section2 {margin-top:67px;} */
.section2 .content-wrap{padding:60px 0 120px 0; display: flex; align-items: center; justify-content: space-between;}
.section2 .content-wrap .left{padding: 7px 15px 7px 0;}
.section2 .content-wrap .left .ltop{color:  #E45457; font-family: 'Pretendard'; font-size: 20px; font-style: normal; font-weight: 600; line-height: normal;}
.section2 .content-wrap .left h1{margin-top:12px;}
.section2 .content-wrap .left h1 strong{color:#E45457;}
.section2 .content-wrap .left p{margin-top:24px;}
.section2 .content-wrap .left .btn-wrap{margin-top:48px; display: flex; align-items: flex-start; gap: 24px;}
.section2 .content-wrap .left .btn-wrap .btn{display: flex; width: 200px; padding: 13px 25px; justify-content: center; align-items: center; border-radius: 100px; font-family: 'Pretendard'; font-size: 20px; font-style: normal; font-weight: 600; line-height: normal;}
.section2 .content-wrap .video-wrap{position: relative;}
.section2 .content-wrap .yt-link{position: absolute; text-align: center; line-height: 0; width: auto; height: auto; top: 50%; left: 50%; transform: translate(-50%, -50%);}


.section3{background: #203864;}
.section3 .content-wrap{padding:9px 0; display: flex; justify-content: space-between; align-items: center; flex-shrink: 0;}
.section3 .tit{color: #FFF; font-family: 'Pretendard'; font-size: 32px; font-style: normal; font-weight: 700; line-height: normal;}
.section3 .tit span{color:#65FFF5;}
.section3 p{margin-top:12px; color: #FFF; font-family: 'Pretendard'; font-size: 20px; font-style: normal; font-weight: 500; line-height: normal;}

.section4 .content-wrap{padding:120px 0;}
.section4 .tit{color: #2F2F2F; text-align: center; font-family: 'Pretendard'; font-size: 24px; font-style: normal; font-weight: 400; line-height: 180%;}
.section4 .swiper{margin-top:36px; position: relative;}
.section4 .swiper .slider_wrap{display: flex; align-items: flex-start; gap: 24px;}
.section4 .swiper .slider_wrap .slider_item{ width: auto; height: auto;}
.section4 .swiper .arrow{position: absolute; top: 42%;transform: translateY(-50%);}
.section4 .swiper .arrow.left-arrow{left: -34px; z-index: 100;}
.section4 .swiper .arrow.right-arrow{right: -34px; z-index: 100;}
.section4 .swiper .swptext{margin-top:12px; color: #717171; font-family: 'Pretendard'; font-size: 18px; font-style: normal; font-weight: 500; line-height: 180%; padding-left:15px;}
.section4 .content{margin-top:60px; display: flex; align-items: center; justify-content: space-between;}
.section4 p{margin-top:24px;}
.section4 .btn-wrap{margin-top:36px; display: flex; align-items: flex-start;}
.section4 .btn-wrap .btn{display: flex; width: 240px; height: 52px; justify-content: center; align-items: center; border-radius: 100px; font-family: 'Pretendard'; font-size: 22px; font-style: normal; font-weight: 500; line-height: normal;}

.section5{background: #F5F5F5;}
.section5 .content-wrap{padding:120px 0;}
.section5 .content{display: flex; align-items: center; justify-content: space-between;}
.section5 .content .right{min-width: 300px;}
.section5 .list{display: flex; flex-wrap: wrap; gap: 10px; justify-content: center; max-width: 632px;}
.section5 .list .list-item{padding: 12px 20px; display: flex; gap: 16px; align-items: center; justify-content: center; border-radius: 12px; background: #FFF; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);}
.section5 .list .list-item:nth-child(n+4){margin-top:22px;}
.section5 .list .list-name{color: #717171; font-family: 'Pretendard'; font-size: 20px; font-style: normal; font-weight: 600; line-height: 180%;}
.section5 h2{text-align: right;}
.section5 p{margin-top:24px; text-align: right; font-weight: 400;}
.section5 .btn-wrap{margin-top:38px; display: flex; align-items: flex-start; justify-content: flex-end;}
.section5 .btn-wrap .btn{display: flex; width: 240px; height: 52px; justify-content: center; align-items: center; border-radius: 100px; font-family: 'Pretendard'; font-size: 22px; font-style: normal; font-weight: 500; line-height: normal;}

.section6 .content-wrap{padding:120px 0;}
.section6 .content{display: flex; align-items: center; justify-content: space-between;}
.section6 p{margin-top:24px; font-weight: 400;}
.section6 .btn-wrap{margin-top:38px; display: flex; align-items: flex-start;}
.section6 .btn-wrap .btn{display: flex; width: 240px; height: 52px; justify-content: center; align-items: center; border-radius: 100px; font-family: 'Pretendard'; font-size: 22px; font-style: normal; font-weight: 500; line-height: normal;}
.section6 .list{display: flex; flex-wrap: wrap; max-width: 600px; gap: 12px; justify-content: flex-end;}
.section6 .list .list-item:nth-child(n+4){margin-top:24px;}
.section6 .list .list-item .list-name{margin-top:8px; text-align: center; color: #717171; font-family: 'Pretendard'; font-size: 18px; font-style: normal; font-weight: 500; line-height: 180%;}

.section7{background: #F5F5F5;}
.section7 .content-wrap{padding:120px 0;}
.section7 .content{display: flex; gap: 68px; align-items: center; justify-content: space-between;}
.section7 .img-wrap{max-width: 680px; width: 100%;}
.section7 h2{text-align: right;}
.section7 p{margin-top:24px; text-align: right; font-weight: 400;}
.section7 .btn-wrap{margin-top:38px; display: flex; align-items: flex-start; justify-content: flex-end;}
.section7 .btn-wrap .btn{display: flex; width: 240px; height: 52px; justify-content: center; align-items: center; border-radius: 100px; font-family: 'Pretendard'; font-size: 22px; font-style: normal; font-weight: 500; line-height: normal;}

.section8 .content-wrap{padding:120px 0;}
.section8 .content{display: flex; align-items: center; justify-content: space-between;}
.section8 p{margin-top:24px; font-weight: 400;}
.section8 .btn-wrap{margin-top:38px; display: flex; align-items: flex-start;}
.section8 .btn-wrap .btn{display: flex; width: 240px; height: 52px; justify-content: center; align-items: center; border-radius: 100px; font-family: 'Pretendard'; font-size: 22px; font-style: normal; font-weight: 500; line-height: normal;}

.section9{border-top:1px solid #DFDFDF;}
.section9 .content-wrap{padding:100px 0 120px 0;}
.section9 p{margin-top:24px; text-align: center;}
.section9 .list{margin-top:48px; padding: 0 30.5px; display: flex; gap: 40px; flex-wrap: wrap; justify-content: center; align-items: center;}
.section9 .list .img-wrap{width: 240px; height: 56px; text-align: center;}

.section10{margin-top:120px; background: #203864; overflow-x:initial !important;}
.section10 .content-wrap{padding:24px 0;}
.section10 .text{color: #FFF; font-family: 'Pretendard'; font-size: 20px; font-style: normal; font-weight: 700; line-height: 160%;}
.section10 .subtext{color: #BCC3D1; font-family: 'Pretendard'; font-size: 16px; font-style: normal; font-weight: 500; line-height: 160%;}
.section10 .ipt-wrap{padding:16px 0; display: flex; align-items: center; justify-content: flex-start; gap: 8px;}
.section10 .ipt-wrap input{display: flex; width: 240px; padding: 12px; align-items: center; gap: 4px; border-radius: 8px; border: 1px solid #D4D4D4; background: #FFF; color: #A3A3A3; font-family: 'Pretendard'; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;}
.section10 .ipt-wrap a.btn, .section10 .ipt-wrap button.btn{height: 43px; padding: 10px 32px; justify-content: center; align-items: center; gap: 10px; border-radius: 8px; background: #2CD8FF; color: #203864; text-align: center; font-family: 'Pretendard'; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal; cursor: pointer;}
.section10 .checkbox-wrap{display: flex; align-items: center; justify-content: flex-start; }
.section10 .checkbox-wrap input{width: 16px; height: 16px;}
.section10 .checkbox-wrap label{margin-left: 4px; color: #BCC3D1; font-family: 'Pretendard'; font-size: 13px; font-style: normal; font-weight: 400; line-height: normal;}

.popup-wrapper-personal{display: none; position: absolute; z-index: 10; top: -60px;box-shadow: 3px 14px 20px 1px #11113b;}
.popup-wrapper-personal.open{display: block;}
.popup-personal{padding:16px; width: 400px; border-radius: 8px; background: #FFF; margin: 0; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);}
.popup-personal .ipt-wrap{margin-top: 24px; margin-bottom: 24px;}
.popup-personal .ipt-wrap input{width: 100%; height: 100%; color: #A3A3A3; font-family: 'Pretendard'; font-size: 14px; font-style: normal; font-weight: 500; line-height: normal; display: flex; padding: 12px; align-items: center; gap: 4px; border-radius: 8px; border: 1px solid #D4D4D4; background: #FFF;}
.popup-personal .ipt-wrap input:nth-child(n+1){margin-top: 12px;}
.popup-personal .checkbox-wrap{display: block;}
.popup-personal .checkbox-wrap:nth-child(n+3){margin-top:20px;}
.popup-personal .checkbox-wrap .with-label{margin-bottom:8px; display: flex; align-items: center; gap: 8px;}
.popup-personal .checkbox-wrap .with-label input{width: 16px; height: 16px;}
.popup-personal .checkbox-wrap .with-label label{color: #000; font-family: 'Pretendard'; font-size: 13px; font-style: normal; font-weight: 700; line-height: normal;}
.popup-personal .subcheck{text-indent: 35px; color: #6D6D6D; font-family: 'Pretendard'; font-size: 13px; font-style: normal; font-weight: 400; line-height: 180%;}
.popup-personal .btn-wrap{position: absolute; right: 16px;}
.popup-personal a.btn-close{width: 14px; height: 14px; content: ""; display: inline-block;  background: url(../../images/pages/ContentGuide/icon-x.png) center / contain no-repeat;}

.popup-wrapper{display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 10;}
.popup-wrapper.open{display: block;}
.popup{padding:32px 16px; width: 328px; border-radius: 12px; background: #FFF; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); margin: 0;}
.popup .tit{margin-top:12px; color: #000; text-align: center; font-family: 'Pretendard'; font-size: 18px; font-style: normal; font-weight: 700; line-height: 160%;}
.popup p{margin-top:12px;text-align: center; color: #9F9F9F; font-family: 'Pretendard'; font-size: 14px; font-style: normal; font-weight: 500; line-height: 160%;}
.popup .ipt-wrap{margin-top: 24px; margin-bottom: 24px;}
.popup .ipt-wrap input{width: 100%; height: 100%; color: #A3A3A3; font-family: 'Pretendard'; font-size: 14px; font-style: normal; font-weight: 500; line-height: normal; display: flex; padding: 12px; align-items: center; gap: 4px; border-radius: 8px; border: 1px solid #D4D4D4; background: #FFF;}
.popup .ipt-wrap input:nth-child(n+1){margin-top: 12px;}
.popup .checkbox-wrap:nth-child(n+1){margin-top:16px;}
.popup .checkbox-wrap .with-label{margin-bottom:8px; display: flex; align-items: center; justify-content: flex-start; gap: 4px;}
.popup .checkbox-wrap .with-label input{width: 16px; height: 16px;}
.popup .checkbox-wrap .with-label label{color: #000; font-family: 'Pretendard'; font-size: 12px; font-style: normal; font-weight: 700; line-height: normal;}
.popup .subcheck{text-indent: 26px; color: #6D6D6D; font-family: 'Pretendard'; font-size: 11px; font-style: normal; font-weight: 400; line-height: 180%;}
.b-btn{margin-top:24px;}
.b-btn a.btn{color: #FFF; text-align: center; font-family: 'Pretendard'; font-size: 14px; font-style: normal; font-weight: 700; line-height: normal; display: flex; height: 43px; padding: 10px 32px; justify-content: center; align-items: center; gap: 10px; border-radius: 8px; background: #203864;}
.b-btn button.btn{color: #FFF; text-align: center; font-family: 'Pretendard'; font-size: 14px; font-style: normal; font-weight: 700; line-height: normal; display: flex; height: 43px; padding: 10px 32px; justify-content: center; align-items: center; gap: 10px; border-radius: 8px; background: #203864; width:100%; cursor:pointer;}
.popup .btn-wrap{text-align: right;}
.popup a.btn-close{width: 14px; height: 14px; content: ""; display: inline-block;  background: url(../../images/pages/ContentGuide/icon-x.png) center / contain no-repeat;}

@media (max-width: 800px) {
    .display-pc{display: none !important;}
    .display-mo{display: block !important;}
    section > .container{padding: 0px 16px;}

    .container h1{font-size: 32px; line-height: 140%;}
    .container h2{font-size: 32px; line-height: 140%;}
    .container h3{font-size: 24px; line-height: 140%;}
    .container p{font-size: 15px; font-weight: 400;}

    .section1 .container .btn-wrap{margin: 16px 0 24px 0; display: flex; gap: 8px; justify-content: flex-end;}
    .section1 .container .btn-wrap a.btn{width: auto; padding: 0 16px;font-size: 14px; font-style: normal; font-weight: 600; height: 36px; border-radius: 8px; border: 1px solid #D2D2D2;}
    
    .section2 .content-wrap{padding:20px 0 80px 0; display: block; }
    .section2 .content-wrap .left{padding: 0;}
    .section2 .content-wrap .left .ltop{font-size: 13px;}
    .section2 .content-wrap .left h1{margin-top:8px;}
    .section2 .content-wrap .left h1 strong{color:#E45457;}
    .section2 .content-wrap .left p{margin-top:12px;}
    .section2 .content-wrap .left .btn-wrap{margin-top:24px; gap: 8px;}
    .section2 .content-wrap .left .btn-wrap .btn{width: 180px; font-size: 16px;}
    .section2 .video-wrap{margin-top:12px; text-align: center;}
    .section2 .content-wrap .yt-link img{width: 15vw;}

    .section3 .content-wrap{padding:24px 0; display: flex; justify-content: space-between; align-items: center; flex-shrink: 0;}
    .section3 .tit{font-size: 24px;}
    .section3 p{font-size: 13px;}
    .section3 .ad{position: absolute; width: 90px; height: 62px; right: 16px; top: 18px;}

    .section4 .content-wrap{padding:80px 0;}
    .section4 .tit{font-size: 15px;}
    .section4 .swiper{overflow-x: auto !important; width: 100%; overflow: hidden; margin-top:16px; position: relative;}
    .section4 .swiper .slider_wrap{overflow: hidden; gap: 12px;}
    .section4 .swiper .slider_wrap .slider_item{width: 100%;}
    .section4 .swiper .swptext{width: 148px; margin-top:4px; font-size: 13px;}
    
    .section4 .content{margin-top:60px; display: block; text-align: center;}
    .section4 p{margin-top:12px;}
    .section4 .btn-wrap{margin-top:16px; justify-content: center;}
    .section4 .btn-wrap .btn{width: 160px; height: 36px; font-size: 16px; font-weight: 600;}
    .section4 .img-wrap{margin-top:20px;}

    .section5 .content-wrap{padding:80px 0;}
    .section5 .content{flex-direction: column-reverse;}
    .section5 .content .right{min-width: 0;}
    .section5 .list{margin-top: 48px; display: flex; flex-wrap: wrap; gap: 8px; max-width: 632px;}
    .section5 .list .list-item{width: 31%; padding: 8px; gap: 3px; flex-direction: column;}
    .section5 .list .list-item:nth-child(n+4){margin-top: 4px;}
    .section5 .list .list-item img{max-width: 36px; max-height: 36px;}
    .section5 .list .list-name{font-size: 14px;}
    .section5 h2{text-align: center;}
    .section5 p{margin-top:12px; text-align: center;}
    .section5 .btn-wrap{margin-top:16px; justify-content: center;}
    .section5 .btn-wrap .btn{width: 160px; height: 36px; font-size: 16px; font-weight: 600;}

    .section6 .content-wrap{padding:80px 0;}
    .section6 .content{flex-direction: column;}
    .section6 h2{text-align: center;}
    .section6 p{text-align: center;}
    .section6 .btn-wrap{margin-top:16px; justify-content: center;}
    .section6 .btn-wrap .btn{width: 160px; height: 36px; font-size: 16px; font-weight: 600;}
    .section6 .list{margin-top:48px; max-width: 600px; justify-content: center;}
    .section6 .list .list-item:nth-child(n+4){margin-top:24px;}
    .section6 .list .list-item{width: 30%;}
    .section6 .list .list-item .list-name{font-size: 13px; line-height: normal;}

    .section7 .content-wrap{padding:80px 0;}
    .section7 .content{flex-direction: column-reverse; gap: 48px;}
    .section7 .img-wrap{max-width: 680px; width: 100%;}
    .section7 h2{text-align: center;}
    .section7 p{margin-top:12px; text-align: center; font-weight: 400;}
    .section7 .btn-wrap{margin-top:16px; justify-content: center;}
    .section7 .btn-wrap .btn{width: 160px; height: 36px; font-size: 16px; font-weight: 600;}

    .section8 .content-wrap{padding:80px 0;}
    .section8 .content{flex-direction: column;}
    .section8 h2{text-align: center;}
    .section8 p{margin-top:12px; text-align: center;}
    .section8 .btn-wrap{margin-top:16px; justify-content: center;}
    .section8 .btn-wrap .btn{width: 160px; height: 36px; font-size: 16px; font-weight: 600;}
    .section8 .img-wrap{margin-top:48px;}

    .section9 .content-wrap{padding:80px 0;}
    .section9 p{font-size: 13px; margin-top:24px; text-align: center;}
    .section9 .list{padding: 0; gap: 12px;}
    .section9 .list .img-wrap{width: 100px; height: 28px;}
    .section9 .list .img-wrap:nth-child(n+3){margin-top: 12px;}
    .section9 .list .img-wrap img{width: 100%; height: auto; max-height: 30px;}

    .section10{margin-top: 0;}
    .section10 a.btn{width: 100%; height: 80px; color: #FFF; text-align: center; font-family: 'Pretendard'; font-size: 18px; font-style: normal; font-weight: 700; line-height: normal;}
}